.member {
    &__buttongroup {
        @extend %mb-30;
        li {
            @extend %rel;
            @extend %flex;
            @extend %align-items-center;
            width: 150px;
            padding: 12px 15px;
            @extend %m-5;
            @extend %c-pointer;
            background: $white-color;
            border: 1px solid rgba($title-color, $alpha: .1);
            @include add-prefix(border-radius, (4px));
            &:nth-child(1) {
                .banner__inputlist--icon {
                    i {
                        color: $theme-color;
                    }
                }
                &.is-checked {
                    color: $theme-color;
                    border-color: $theme-color;
                    .member {
                        &__radio {
                            background: $theme-color;
                            &::after {
                                border-color: $theme-color;
                            }
                        }
                    }
                } 
            }
            &:nth-child(2) {
                .banner__inputlist--icon {
                    i {
                        color: $theme-color3;
                    }
                }
                &.is-checked {
                    color: $theme-color3;
                    border-color: $theme-color3;
                    .member {
                        &__radio {
                            background: $theme-color3;
                            &::after {
                                border-color: $theme-color3;
                            }
                        }
                    }
                } 
            }
            &:nth-child(3) {
                .banner__inputlist--icon {
                    i {
                        color: $theme-color4;
                    }
                }
                &.is-checked {
                    color: $theme-color4;
                    border-color: $theme-color4;
                    .member {
                        &__radio {
                            background: $theme-color4;
                            &::after {
                                border-color: $theme-color4;
                            }
                        }
                    }
                } 
            }
            &.is-checked {
                @extend %theme-color;
                border-color: $theme-color;
                .member {
                    &__radio {
                        @extend %bg-theme;
                        &::after {
                            border-color: $theme-color;
                        }
                    }
                }
            }
            input {
                width: auto;
                @extend %mr-10;
            }
        }
    }
    &__radio {
        width: 8px;
        height: 8px;
        background: $body-color4;
        @extend %bg-white;
        @extend %mr-10;
        @extend %p-rel;
        @include add-prefix(border-radius, (100%));
        &::after {
            @extend %pos-center;
            width: 16px;
            height: 16px;
            @include add-prefix(border-radius, (100%));
            border: 1px solid $border-color;
        }
    }
    &__grid {

    }
    &__item {
        width: calc(100% / 1);
        @include breakpoint(sm) {
            width: calc(100% / 2);
        }
        @include breakpoint(lg) {
            width: calc(100% / 4);
        }
        @include breakpoint(xxl) {
            width: calc(100% / 5);
        }
    }
    &__inner {
        margin: 12px;
        padding: 10px;
        @extend %bg-white;
        @include add-prefix(border-radius, (4px));
        @include transition($transition);
        &:hover {
            box-shadow: 0 20px 30px rgba($shadow-color, $alpha: .3);
            .member {
                &__thumb {
                    img {
                        @include add-prefix(transform, (scale(1.3)));
                    }
                }
                &__content {
                    h5 {
                        color: $theme-color;
                    }
                }
            }
        }
    }
    &__thumb {
        @extend %of-hidden;
        @extend %p-rel;
        img {
            @extend %w-100;
        }
    }
    &__activity {
        @extend %p-abs;
        padding: 0px 10px;
        z-index: 1;
        bottom: 10px;
        left: 10px;
        @include font-size(14px);
        @extend %white-color;
        background: $theme-color2;
        @include add-prefix(border-radius, (4px));
        &--ofline {
            background: $ash-color;
            color: $title-color;
        }
    }
    &__content {
        padding: 25px 15px 20px;
        @extend %text-center;
        h3 {
            @extend %mb-10;
        }
        h5 {
            @extend %text-1;
            @extend %mb-0;
        }
        p {
            @extend %mb-0;
            i {
                &.fa-mars {
                    color: $theme-color3;
                }
                &.fa-venus {
                    color: $theme-color4;
                }
            }
        }
    }
    &__info {
        @extend %flex;
        @extend %justify-between;
        @extend %align-items-center;
        &--left {
            @extend %flex;
            @extend %justify-between;
            @extend %align-items-center;
            .default-btn {
                background: $theme-color;
                span {
                    @extend %white-color;
                }
                &:hover {
                    @include add-prefix(border-radius, (4px));
                    letter-spacing: .5px;
                    span {
                        @extend %white-color;
                    }
                }
            }
        }
        &--filter {
            @extend %mr-30;
            @extend %c-pointer;
            @include breakpoint(max-sm) {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }
        &--count {
            @extend %p-rel;
            .default-btn {
                background: $theme-color8;
                @include add-prefix(border-radius, (4px 0 0 4px));
                span {
                    color: $theme-color3;
                }
                &:hover {
                    @include add-prefix(border-radius, (0px));
                    span {
                        color: $theme-color3;
                    }
                }
            }
            p {
                @extend %p-abs;
                left: 100%;
                top: 0;
                @extend %mb-0;
                background: $theme-color3;
                color: $white-color;
                // @extend %w-100;
                @extend %h-100;
                line-height: 50px;
                @extend %text-center;
                padding-inline: 15px;
                @include add-prefix(border-radius, (0 4px 4px 0));
                &::after {
                    @extend %p-abs;
                    left: -10px;
                    top: 50%;
                    @include arrow(left, 10px, $theme-color3);
                    @include add-prefix(transform, (translateY(-50%)));
                }
            }
        }
        &--right {
            @include breakpoint(max-lg) {
                // width: 50%;
                margin-top: 15px;
            }
            select {
                background: $theme-color3;
                color: $white-color;
                @include add-prefix(border-radius, (0px));
            }
        }
        &--customselect {
            @extend %flex;
            .default-btn {
                background: $white-color;
                @extend %p-rel;
                &::after {
                    @extend %p-abs;
                    right: -1px;
                    top: 50%;
                    @include arrow(left, 10px, $theme-color3);
                    @include add-prefix(transform, (translateY(-50%)));
                }
                span {
                    color: $title-color;
                }
                &:hover {
                    @include add-prefix(border-radius, (0px));
                    letter-spacing: .5px;
                }
            }
            .banner__inputlist {
                margin-bottom: 0;
                background: $theme-color3;
                border: none;
                @include add-prefix(border-radius, (0 4px 4px 0));
            }
            select {
                width: 140px;
                border: none;
            }
        }
    }
    &__pagination {
        @extend %flex;
        @extend %align-items-center;
        @extend %justify-center;
        @include breakpoint(lg) {
            justify-content: space-between;
        }
    }
    &--style2 {
        .member {
            &__inner {
                padding: 30px;
                &:hover {
                    .member {
                        &__thumb {
                            img {
                                @include add-prefix(transform, (scale(1)));
                            }
                        }
                    }
                }
            }
            &__thumb {
                width: 160px;
                height: 160px;
                margin-inline: auto;
                overflow: visible;
                @extend %mb-20;
                @include add-prefix(border-radius, (100%));
                img {
                    @extend %w-100;
                    @include add-prefix(border-radius, (100px));
                }
            }
            &__content {
                @extend %p-rel;
                @extend %p-0;
                h3 {
                }
                h5 {
                    @extend %mb-0;
                }
                p {
                    i { }
                }
            }
            &__activity {
                width: 16px;
                height: 16px;
                left: auto;
                right: 20px;
                border: 3px solid $white-color;
                @extend %p-0;
                @include add-prefix(border-radius, (100%));
                &--ofline {
                    background: #c3c3c3;
                }
            }
            &__tab {
                justify-content: center;
                @extend %mb-30;
                @extend %border-none;
                li {
                    @extend %m-5;
                    button {
                        padding: 15px 20px;
                        @extend %c-pointer;
                        border: 1px solid rgba($title-color, $alpha: .1) !important;
                        @include add-prefix(border-radius, (4px));
                        @extend %bg-white;
                        &.active {
                            @extend %theme-color;
                            border-color: $theme-color !important;
                        }
                    }
                }
            }
        }
        // .section__wrapper {
        //     margin-top: 18px;
        // }
    }
    &--style3 {
        .member {
            &__item {
                @include breakpoint(lg) {
                    width: calc(100% / 4);
                }
            }
            &__inner {
                padding: 0px;
                @extend %p-rel;
                @extend %of-hidden;
                &:hover {
                    .member {
                        &__thumb {
                            img {
                                @include add-prefix(transform, (scale(1.2)));
                            }
                            &::after {
                                opacity: 1;
                            }
                        }
                        &__content {
                            bottom: 0;
                            h5 {
                                color: $white-color;
                            }
                        }
                    }
                }
            }
            &__thumb {
                @extend %p-rel;
                &::after {
                    @extend %p-abs;
                    @extend %w-100;
                    height: 50%;
                    left: 0;
                    bottom: 0%;
                    opacity: 0;
                    background-image: linear-gradient(to top, #051937eb, #1712288f, #190a1a26, #12040d00, #00000000);
                    @include add-prefix(transition, (all .3s ease));
                }
                img {
                    @extend %w-100;
                }
            }
            &__content {
                @extend %p-abs;
                bottom: -100px;
                left: 0;
                @extend %w-100;
                @include add-prefix(transition, (all .6s ease));
                h5 {
                    @extend %mb-0;
                    @extend %white-color;
                }
                p {
                    @extend %white-color;
                    i { }
                }
            }
        }
        .default-btn {
            background: $theme-color6;
            &:hover {
                span {
                    @extend %white-color;
                }
            }
            span {
                @extend %white-color;
            }
        }
    }
    // .story--style2 {
    //     .section__wrapper {
    //         margin-top: 18px;
    //     }
    // }
    
}
.lightcase-contentInner {
    .groupmodal {
        display: block;
    }
}
a[class*='lightcase-icon-'].lightcase-icon-close, #lightcase-case {
    z-index: 999999;
}
a[class*='lightcase-icon-'] {
    z-index: 999999;
}
#lightcase-overlay {
    z-index: 99999;
}
.groupmodal {
    display: none;
    &__area {}
    &__thumb {
        img, iframe {
            @extend %w-100;
        }
    }
    &__content {
        .activity__inner {
            border: none;
            padding: 0;
        }
        .activity__content {
            h5 {
                margin-bottom: 0;
            }
        }
    }
    &__publicmode {
        font-family: "icoFont" !important;
        @extend %d-inline-block;
        width: auto;
        appearance: none;
    }
}